const Task = ({ task }) => {
  return (
    <div className="row">
      <p className="tasker-name">{task?.name}:</p>
      <p className="task-icon">{task?.task.substring(task.task.length - 3)}</p>
      <p>
        {task?.done === true ? (
          <span className="done-icon">✅</span>
        ) : (
          <span className="done-icon"> ❌</span>
        )}
      </p>
    </div>
  )
}

export default Task
