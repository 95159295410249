import TaskItem from './TaskItem'

const TaskHistory = ({ taskData, formattedDate }) => {
  const filteredTasks = taskData.filter(
    (task) => task.date.substring(0, 10).toString() !== formattedDate.toString()
  )
  return (
    <div className="recent-tasks {">
      {filteredTasks.map((task) => {
        return <TaskItem key={task._id} tasks={task.tasks} date={task.date} />
      })}
    </div>
  )
}
export default TaskHistory
