import { useEffect, useState } from 'react'

const TaskBox = ({ todayData, markTaskDone }) => {
  const nyt = new Date()
  const hour = nyt.getHours()
  const minute = nyt.getMinutes()
  const second = nyt.getSeconds()

  const timeNow = `${hour}:${minute}:${second}`

  const handleClick = (event, task) => {
    event.preventDefault()

    markTaskDone(task)
  }
  return (
    <div>
      {todayData?.tasks?.map((task) => {
        return (
          <div
            onClick={(event) => handleClick(event, task)}
            className="task-box"
            key={task.name}
          >
            {task.done === false ? (
              <>
                <p className="task-name">{task.name}</p>
                <p className="task">{task.task ? task.task : '?'} </p>
              </>
            ) : (
              <div className="done-div">
                <p className="done-name">{task.name}</p>
                <p className="done-task">{task.task ? task.task : '?'} </p>
                <p className="checkmark"> {`✅ (${task.user})`}</p>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default TaskBox
