import { useState, useEffect, useRef } from 'react'
import TaskBox from './components/TaskBox'
import Pet from './components/Pet'
import TaskHistory from './components/TaskHistory'
import './App.css'
import axios from 'axios'
import moment from 'moment-timezone'
import { FiRefreshCcw } from 'react-icons/fi'
import PullToRefresh from 'react-simple-pull-to-refresh'
import { ThreeCircles } from 'react-loader-spinner'
import { MdOutlineExpandCircleDown } from 'react-icons/md'

const Expand = () => {
  return <MdOutlineExpandCircleDown />
}

const App = () => {
  const [isDrawn, setIsDrawn] = useState(false)
  const [data, setData] = useState([])
  const [todayData, setTodayData] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [petData, setPetData] = useState([])
  const [todayPetData, setTodayPetData] = useState(false)
  const [allTaskData, setAllTaskData] = useState([])
  const [showHistory, setShowHistory] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [user, setUser] = useState(null)

  const [dogFood, setDogFood] = useState({
    morningFood: false,
    eveningFood: false,
    user: null,
  })
  const [dogOut, setDogOut] = useState({
    morningOut: false,
    dayOut: false,
    eveningOut: false,
    user: null,
  })
  const tasks = ['Roskat 🗑️', 'Lattiat 🧹', 'Tasot 🧼']
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`

  const fetchData = async () => {
    const taskData = await axios.get('/api/tasks')
    const petData = await axios.get('/api/pet')

    setPetData(petData.data[petData.data.length - 1])
    setData(taskData.data[taskData.data.length - 1])
    setAllTaskData(taskData.data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [isDrawn, isLoading, todayPetData])

  useEffect(() => {}, [dogFood, dogOut])

  useEffect(() => {
    if (data?.date?.substring(0, 10).toString() === formattedDate.toString()) {
      setTodayData(true)
    } else {
      setTodayData(false)
    }
    if (
      petData?.date?.substring(0, 10).toString() === formattedDate.toString()
    ) {
      setTodayPetData(true)
    } else {
      setTodayPetData(false)
    }
  }, [data, formattedDate, user])

  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem('loggedHomeappUser')
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON)
      setUser(user)
    }
  }, [setDogFood, setDogOut])

  const petClick = async (action) => {
    const id = petData._id.toString()

    let fieldToUpdate = {}
    if (action === 'Dentastix') {
      fieldToUpdate = {
        field: 'dentastix',
        value: true,
        currentUserName: user.data.name,
      }
      setDogFood({ ...dogFood, dentastix: true })
    }
    if (action === 'Aamulenkki') {
      fieldToUpdate = {
        field: 'morningOut',
        value: true,
        currentUserName: user.data.name,
      }
      setDogOut({ ...dogOut, morningOut: true })
    }

    if (action === 'Päivälenkki') {
      fieldToUpdate = {
        field: 'dayOut',
        value: true,
        currentUserName: user.data.name,
      }
      setDogOut({ ...dogOut, dayOut: true })
    }

    if (action === 'Iltalenkki') {
      fieldToUpdate = {
        field: 'eveningOut',
        value: true,
        currentUserName: user.data.name,
      }
      setDogOut({ ...dogOut, eveningOut: true })
    }

    if (action === 'Aamuruoka') {
      fieldToUpdate = {
        field: 'morningFood',
        value: true,
        currentUserName: user.data.name,
      }
      setDogFood({ ...dogFood, morningFood: true })
    }

    if (action === 'Iltaruoka') {
      fieldToUpdate = {
        field: 'eveningFood',
        value: true,
        currentUserName: user.data.name,
      }
      setDogFood({ ...dogFood, eveningFood: true })
    }

    const currentUserName = user.data.name
    console.log(currentUserName)

    await axios.put(`/api/pet/${id}`, fieldToUpdate)

    /*    const updatedData = {
      ...response.data,
      time: moment(response.data.time)
        .tz('Europe/Helsinki')
        .format('YYYY-MM-DD HH:mm:ss'),
      user: currentUserName,
    }
    
 */

    fetchData()
  }

  const onRefresh = () => {
    window.location.reload()
    return new Promise((resolve) => {
      // resolve the Promise when the refresh is complete
      resolve()
    })
  }
  const taskLottery = () => {
    const randomTask = tasks[Math.floor(Math.random() * 3)]

    const newArray = tasks.filter((task) => {
      return task !== randomTask
    })
    const randomTask2 = newArray[Math.floor(Math.random() * 2)]
    const randomTask3 = newArray
      .filter((task) => task !== randomTask2)
      .toString()

    const drawnTasks = {
      mikaelTask: randomTask,
      jenniTask: randomTask2,
      almaTask: randomTask3,
    }
    const url = '/api/tasks'

    axios.post(url, drawnTasks).then((res) => res.data)
    axios.post('/api/pet', {}).then((res) => res.data)

    setIsDrawn(true)
    setTodayPetData(true)
    fetchData()
  }

  const markTaskDone = async (task) => {
    if (task.done === true) {
      //tähän sitten taskin undonaus
    } else {
      const isConfirmed = window.confirm(
        `Ookko ${task.name} varmasti hoitanunna ${task.task.toLowerCase()} ?`
      )

      if (isConfirmed) {
        const id = todayData ? data._id.toString() : ''

        const getResponse = await axios.get(`/api/tasks/${id}`)

        const tasks = getResponse.data.tasks

        const taskIndex = tasks.findIndex((t) => t.name === task.name)
        tasks[taskIndex].done = true
        tasks[taskIndex].user = user.data.name

        const updatedTask = { tasks: tasks }
        console.log(updatedTask)

        const putResponse = await axios.put(`/api/tasks/${id}`, updatedTask)

        setData(putResponse.data)
      }
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault()

    const userToLogin = {
      username,
      password,
    }

    const user = await axios.post('/api/login', userToLogin)
    window.localStorage.setItem('loggedHomeappUser', JSON.stringify(user))
    setUser(user)
    setUsername('')
    setPassword('')
  }

  const loginForm = () => {
    return (
      <form onSubmit={handleLogin} className="login-form">
        <p>käyttäjätunnus</p>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <p>salasana</p>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">kirjaudu</button>
      </form>
    )
  }

  const handleLogOut = (e) => {
    e.preventDefault()
    window.localStorage.clear()
    setUser(null)
  }

  return (
    <PullToRefresh onRefresh={onRefresh}>
      <div className="container">
        {!user ? (
          loginForm()
        ) : (
          <div>
            <div className="navbar">
              <div className="user-name">
                <h3>Hei, {user.data.name}!</h3>{' '}
              </div>
              <div className="logout-btn-div">
                <button className="logout-button" onClick={handleLogOut}>
                  kirjaudu ulos
                </button>
              </div>
            </div>
            <div className="arvonta">
              <h1> {new Date().toLocaleDateString()}</h1>
              {isLoading ? (
                <div className="loader-spinner">
                  <ThreeCircles
                    height="50"
                    width="50"
                    color="#F6F4E8"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                  />
                </div>
              ) : (
                <>
                  {todayData ? (
                    <div className="top-headings">
                      {!todayData && !isDrawn && (
                        <button onClick={taskLottery}>Uusi päivä!</button>
                      )}
                      {todayData && <h2>Päivän työt jaettu!</h2>}
                    </div>
                  ) : (
                    <div>
                      {!todayData && (
                        <button onClick={taskLottery}>Uusi päivä!</button>
                      )}
                    </div>
                  )}
                  <TaskBox todayData={data} markTaskDone={markTaskDone} />
                </>
              )}
            </div>

            {!isLoading ? (
              <div className="expand">
                <span
                  onClick={() => setShowHistory(!showHistory)}
                  className="expand-logo"
                >
                  <Expand />
                </span>
              </div>
            ) : (
              ''
            )}

            {showHistory ? (
              <div className="recent-tasks-container">
                <h4 className="task-history-h4">Menneet kotityöt</h4>
                <TaskHistory
                  taskData={allTaskData.slice().reverse()}
                  formattedDate={formattedDate}
                />
              </div>
            ) : (
              ''
            )}

            <div className="pet-div"></div>
            <Pet onClick={petClick} petData={petData} />
          </div>
        )}
      </div>
    </PullToRefresh>
  )
}

export default App
