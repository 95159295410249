import Task from './Task'

const TaskItem = ({ tasks, date }) => {
  return (
    <div className="history-box">
      <p>{date.substring(0, 10)}</p>
      {tasks.map((task) => (
        <Task key={task._id} task={task} />
      ))}
    </div>
  )
}

export default TaskItem
