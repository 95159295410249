import moment from 'moment-timezone'
import PetActivity from './PetActivity'

const Pet = ({ onClick, petData }) => {
  return (
    <div>
      <div className="food">
        <h2>Tuitun 🐶 🍛 </h2>
        <PetActivity
          actionLabel="Dentastix"
          onClick={onClick}
          action={petData?.dentastix}
        />
        <PetActivity
          actionLabel="Aamuruoka"
          onClick={onClick}
          action={petData?.morningFood}
        />
        <PetActivity
          actionLabel="Iltaruoka"
          onClick={onClick}
          action={petData?.eveningFood}
        />
      </div>
      <div className="outside">
        <h2>Tuitun 🦮</h2>
        <PetActivity
          actionLabel="Aamulenkki"
          onClick={onClick}
          action={petData?.morningOut}
        />
        <PetActivity
          actionLabel="Päivälenkki"
          onClick={onClick}
          action={petData?.dayOut}
        />
        <PetActivity
          actionLabel="Iltalenkki"
          onClick={onClick}
          action={petData?.eveningOut}
        />
      </div>
    </div>
  )
}

export default Pet
