import moment from 'moment-timezone'

const PetActivity = ({ action, actionLabel, onClick }) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // Convert the server time to the user's local time

  const localTime = moment
    .tz(action?.time, 'UTC')
    .tz(userTimezone)
    .format('HH:mm')
  return (
    <div className="pet-items">
      {action?.done === true ? (
        <div className="done-out">
          <p>{`✅`} </p>
          <p className="done-task">{actionLabel} </p>

          <p>{` ${localTime} (${action.user})`}</p>
        </div>
      ) : (
        <div className="button-div">
          <p>{actionLabel}</p>
          <button onClick={() => onClick(actionLabel)}></button>
        </div>
      )}
    </div>
  )
}

export default PetActivity

/*
              <div>
                <div className="food">
                  <h2>Tuitun 🐶 🍛 </h2>

                  <Pet
                    action="Aamuruoka"
                    onClick={petClick}
                    isDone={petData?.morningFood}
                  />
                  <Pet
                    action="Iltaruoka"
                    onClick={petClick}
                    isDone={petData?.eveningFood}
                  />
                </div>
                <div className="outside">
                  <h2>Tuitun 🦮</h2>
                  <Pet
                    action="Aamulenkki"
                    onClick={petClick}
                    isDone={petData?.morningOut}
                  />
                  <Pet
                    action="Päivälenkki"
                    onClick={petClick}
                    isDone={petData?.dayOut}
                  />
                  <Pet
                    action="Iltalenkki"
                    onClick={petClick}
                    isDone={petData?.eveningOut}
                  />
                </div>
              </div>



 */
